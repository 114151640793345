@font-face {
font-family: '__futuraPt_455ff4';
src: url(/_next/static/media/e4b148503565273c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__futuraPt_455ff4';
src: url(/_next/static/media/cde0cb564f9e1862-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__futuraPt_455ff4';
src: url(/_next/static/media/5861e05651efc587-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__futuraPt_455ff4';
src: url(/_next/static/media/c9ab345da76c5f5f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__futuraPt_Fallback_455ff4';src: local("Arial");ascent-override: 111.54%;descent-override: 34.07%;line-gap-override: 0.00%;size-adjust: 88.04%
}.__className_455ff4 {font-family: '__futuraPt_455ff4', '__futuraPt_Fallback_455ff4'
}

